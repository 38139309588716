import { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { Button, TextInput } from "react-native-paper";

import dcdStorage from "./dcdstorage";
import Precincts from "./Precincts";
import { expo as settings } from "../app.json";

export default SpecifyPrecinct;

const logIt = settings.logIt;
let mylog = () => {};
if (logIt) mylog = console.log;

function SpecifyPrecinct({ navigation }) {
  dcdStorage.setItem("precinctText", "");

  return (
    <View>
      <Precincts />
      <GoButton />
    </View>
  );

  function GoButton(props) {
    return (
      <Button
        style={{ width: "100px", margin: "auto", marginTop: "2rem" }}
        mode="contained"
        onPress={(e) => {
          const precinctText = dcdStorage.getItem("precinctText").trim();

          if (!precinctText) {
            alert("Needs a precinct");
            return;
          }
          mylog("Calling ShowVoterData for precinct " + precinctText);
          navigation.navigate("ShowVoterData", {
            precinctData: { precinctText },
          });
        }}
      >
        Go
      </Button>
    );
  }
}

/*                                  *  *  *                                   */
