import { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { Text } from "react-native-paper";

import SpecifyAddress from "./SpecifyAddress";
import api from "./api";
import { expo as settings } from "../app.json";

const logIt = settings.logIt;
let mylog = () => {};
if (logIt) mylog = console.log;

export default Admin;

function Admin({ navigation }) {
  useEffect(async () => {
    navigation.setOptions({
      headerLeft: logout,
      headerRight: viewOptions,
    });
  });

  return (
    <View style={styles.container}>
      <SelectAddresses />
    </View>
  );

  function logout(props) {
    return (
      <Text
        style={{
          marginLeft: "1rem",
          padding: "5px",
          height: "2rem",
          fontSize: "12pt",
          color: "#27b",
        }}
        onPress={() => {
          api.sessionID = null;
          navigation.navigate("Login");
        }}
      >
        Log out
      </Text>
    );
  }

  function viewOptions(props) {
    return (
      <Text
        style={{
          marginLeft: "1rem",
          padding: "5px",
          height: "2rem",
          fontSize: "12pt",
          color: "#27b",
        }}
        onPress={() => {
          navigation.navigate("ViewOptions");
        }}
      >
        View Options
      </Text>
    );
  }

  function SelectAddresses(props) {
    const [checked, setChecked] = useState("Block");

    return <View>{<SpecifyAddress navigation={navigation} />}</View>;
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    // justifyContent: 'top',
  },
  heading: {
    margin: "2rem",
    fontFamily: "sans-serif",
    fontWeight: "bold",
  },
  textInput: {
    margin: "1rem",
    width: "400px",
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "row",
  },
  radioButtonContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "2rem",
    marginLeft: "2rem",
  },
  checkboxWithLabel: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
  },
  checkbox: {
    alignSelf: "center",
  },
  label: {
    margin: 4,
    marginTop: "10px",
  },
});
