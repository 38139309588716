import React from 'react'
import { AppRegistry, StyleSheet } from 'react-native'
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper'
import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { expo as settings } from '../app.json'

import Login from './Login'
import Admin from './Admin'
import ViewOptions from './ViewOptions'
import ShowVoterData from './ShowVoterData'
import ShowAddressData from './ShowAddressData'
import dcdStorage from './dcdstorage'
import dcdData from './dcddata'

const Stack = createNativeStackNavigator();

const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: '#0015bc', // zaffre
    // accent: 'yellow',
    // primary - primary color for your app, usually your brand color.
    // accent - secondary color for your app which complements the primary color.
    // background - background color for pages, such as lists.
    // surface - background color for elements containing content, such as cards.
    // text - text color for content.
    // disabled - color for disabled elements.
    // placeholder - color for placeholder text, such as input placeholder.
    // backdrop - color for backdrops of various components such as modals.
    // onSurface - background color for snackbars
    // notification - background color for badges
  },
};

dcdStorage.initialize().then(dcdData.prime)

export default function App() {

  return (
    <PaperProvider theme={theme}>
      <NavigationContainer>
        <Stack.Navigator screenOptions={{ headerTitleAlign: 'center' }}>
          <Stack.Screen name="Login"
            component={Login}
            options={{
              title: 'User Login',
            }}
          />
          <Stack.Screen name="ViewOptions"
            component={ViewOptions}
            options={{
              title: 'View Options',
            }}
          />
          <Stack.Screen name="Admin"
            component={Admin}
            options={{
              title: 'Admin',
            }}
          />
          <Stack.Screen name="ShowVoterData"
            component={ShowVoterData}
            options={{
              title: 'Voters on Block',
            }}
          />
          <Stack.Screen name="ShowAddressData"
            component={ShowAddressData}
            options={{
              title: 'Address Data',
            }}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </PaperProvider>
  )
}

AppRegistry.registerComponent(settings.name, () => Main)
