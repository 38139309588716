/*
  dcddata.js just has a function to prime the data cache
*/

import dcdStorage from "./dcdstorage";

// import CityData from './citydata'
import StreetData from "./streetdata";
import PrecinctData from "./precinctdata";
import { expo as settings } from "../app.json";

const logIt = settings.logIt;
let mylog = () => {};
if (logIt) mylog = console.log;

export default Object.create(null, {
  prime: { value: prime },
});

async function prime() {
  mylog("dcddata.js/prime");
  const sessionID = dcdStorage.getItem("sessionID");
  mylog(`dcdData.prime() - sessionID is ${sessionID}`);
  if (sessionID) {
    // CityData.load()
    StreetData.load();
    PrecinctData.load();
  }
}

/*                                  *  *  *                                   */
