/*
  streetdata.js manages a list of all the streets in the county
*/
import dcdStorage from "./dcdstorage";
import api from "./api";
import { expo as settings } from "../app.json";

export default {
  load,
};

const logIt = settings.logIt;
const mylog = logIt ? console.log : () => {};

let busy = false;
function load() {
  mylog("Load...");
  if (dcdStorage.getItem("streets")) {
    return;
  }
  if (busy) return;

  if (api.sessionID) {
    busy = true;
    return api
      .fetch(() => {}, "get-streets")
      .then((data = null) => {
        mylog("get-streets has returned!");
        dcdStorage.setItem("streets", data, true);
        busy = false;
        return data;
      });
  } else {
    return null;
  }
}

/*                                  *  *  *                                   */
