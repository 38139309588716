/*
  Streets.jsx is an input widget
*/
import dcdStorage from "./dcdstorage";
import { useState } from "react";
import { View, StyleSheet } from "react-native";
import { TextInput } from "react-native-paper";
import "./suggestion.css";
import { expo as settings } from "../app.json";

export default Streets;

const logIt = settings.logIt;
let mylog = () => {};
if (logIt) mylog = console.log;

let caller;

function Streets(props) {
  caller = props.page;

  const [streetText, setStreetText] = useState("");
  const [streetSuggestions, setStreetSuggestions] = useState([]);

  return (
    <View>
      <TextInput
        style={styles.textInput}
        label="Street"
        value={streetText}
        onChange={(e) => onStreetChangeHandler(e.target.value)}
      />
      {streetSuggestions &&
        streetSuggestions.map((streetSuggestion, i) => (
          <div
            key={i}
            className="suggestion col-md-12 justified-content-md-center"
            onClick={() =>
              onStreetSuggestionHandler(
                streetSuggestion.fullstreetname,
                streetSuggestion.street_key
              )
            }
          >
            {streetSuggestion.fullstreetname}
          </div>
        ))}
    </View>
  );

  function onStreetChangeHandler(text) {
    const streets = dcdStorage.getItem("streets");

    mylog("IN onStreetChangeHandler *******************************");
    let streetMatches = [];
    mylog(`Street Text: ${text} length: ${text.length}`);
    dcdStorage.setItem("streetKeyText", "");
    let lengthToCheck = 0; // This could be different for physical device
    if (streets && streets.filter && text.length > lengthToCheck) {
      const textI = text.toUpperCase();
      streetMatches = streets.filter((street) => {
        return street.fullstreetname.startsWith(textI);
      });
      mylog("matches are ", streetMatches);

      mylog("caller = ", caller);
      if (caller == "SpecifyAddress") {
        const precinctText = dcdStorage.getItem("precinctText");
        mylog("precinctText = ", precinctText);
        if (precinctText != null) {
          streetMatches = streetMatches.filter((street) => {
            return street.precincts.includes(precinctText);
          });
          mylog("matches2 are ", streetMatches);
        }
      }
      setStreetSuggestions(streetMatches);
      setStreetText(text);
    } else {
      setStreetText(text);
      // dcdStorage.setItem('streetText', text)
      setStreetSuggestions([]);
    }
    mylog("OUT onStreetChangeHandler *******************************");
  }

  function onStreetSuggestionHandler(streetName, streetKey) {
    mylog("IN onStreetSuggestionHandler *******************************");
    mylog("Street Suggestion text: " + streetName + " Key: " + streetKey);
    setStreetText(streetName);

    dcdStorage.setItem("streetKeyText", streetKey);
    dcdStorage.setItem("streetText", streetName);

    setStreetSuggestions([]);
    mylog("OUT onStreetSuggestionHandler *******************************");
  }
}
const styles = StyleSheet.create({
  textInput: {
    margin: "1rem",
    width: "400px",
  },
});
/*                                  *  *  *                                   */
