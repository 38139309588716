import { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import {
  ActivityIndicator,
  Button,
  Dialog,
  Paragraph,
  Text,
  TextInput,
} from "react-native-paper";

import api from "./api";
import dcdStorage from "./dcdstorage";
import dcdData from "./dcddata";
import { expo as settings } from "../app.json";

export default Login;

const logIt = settings.logIt;
let mylog = () => {};
if (logIt) mylog = console.log;

// Login state bitmap
const waiting = 1;
const inputEmail = 2;
const requestPasscode = 4;
const inputPasscode = 8;
const requestSessionID = 16;
const badPasscode = 32;
const passcodeSent = 64;

let loginLinkProcessed = false;
const [url, query] = `${window.location || ""}`.split("?");

function Login({ navigation }) {
  const [state, setState] = useState(inputEmail | requestPasscode);
  useEffect(async () => {
    const sessionID = api.sessionID;

    if (sessionID) {
      if (query) {
        // clear the query if we have a valid session
        window.location = url;
      } else {
        navigation.navigate("ViewOptions");
      }
    } else if (query && !loginLinkProcessed) {
      loginLinkProcessed = true;
      processLoginLink(query).then((isValid) => {
        if (isValid) {
          window.location = url;
        } else {
          setState(badPasscode);
        }
      });
    }
  });

  return (
    <View style={styles.container}>
      {Boolean(state & inputEmail) && <InputEmail />}
      {Boolean(state & inputPasscode) && <InputPasscode />}
      {Boolean(state & passcodeSent) && <PasscodeSent />}
      {Boolean(state & waiting) && (
        <ActivityIndicator size="large" style={{ marginTop: "2rem" }} />
      )}
      {Boolean(state & badPasscode) && <BadPasscodeDialog />}
      {Boolean(state & requestPasscode) && <RequestPasscode />}
      {Boolean(state & requestSessionID) && <RequestSessionId />}
      {<ShowMessage />}
      <Text style={{ flexGrow: 1 }} />
    </View>
  );

  function InputEmail(props) {
    const [userEmail, setUserEmail] = useState("");
    useEffect(async () => {
      setUserEmail(dcdStorage.getItem("userEmail"));
    });
    return (
      <TextInput
        // {...props} // Inherit any props passed to it
        style={styles.textInput}
        label="Please enter your email address"
        onChangeText={(newText) => {
          setUserEmail(newText);
          dcdStorage.setItem("userEmail", newText, true);
        }}
        value={userEmail}
      />
    );
  }

  function InputPasscode(props) {
    const [passcode, setPasscode] = useState("");
    return (
      <TextInput
        style={styles.textInput}
        label="Please enter your passcode"
        onChangeText={(newText) => {
          setPasscode(newText);
          dcdStorage.setItem("passcode", newText, true);
        }}
        value={passcode}
      />
    );
  }

  function PasscodeSent(props) {
    const [userEmail, setUserEmail] = useState("");
    useEffect(async () => {
      setUserEmail(dcdStorage.getItem("userEmail"));
    });
    return (
      <Text style={{ fontFamily: "sans-serif" }}>
        A passcode was emailed to {userEmail}
        <br />
        (you may need to check your junk mail folder)
      </Text>
    );
  }

  function ShowMessage(props) {
    const [message, setMessage] = useState("");
    useEffect(async () => {
      setMessage(dcdStorage.getItem("loginErrorMessage"));
    });
    return (
      <Text style={{ fontFamily: "sans-serif", color: "red" }}>{message}</Text>
    );
  }

  function BadPasscodeDialog(props) {
    return (
      <Dialog visible={true}>
        <Dialog.Title>Invalid Passcode</Dialog.Title>
        <Dialog.Content>
          <Paragraph>
            Sorry, that passcode is no longer valid. Please try again.
          </Paragraph>
        </Dialog.Content>
        <Dialog.Actions>
          <Button
            onPress={() => {
              window.location = url;
            }}
          >
            Done
          </Button>
        </Dialog.Actions>
      </Dialog>
    );
  }

  function RequestPasscode(props) {
    return (
      <Button
        style={styles.button}
        mode="contained"
        onPress={async (evt) => {
          mylog(`RequestPasscode selected`);
          dcdStorage.removeItem("loginErrorMessage");
          if (!dcdStorage.getItem("userEmail")) {
            alert(`We need your email address to send you a passcode`);
            return;
          }
          mylog(`setState(inputPasscode | passcodeSent | requestSessionID)`);
          setState(inputPasscode | passcodeSent | requestSessionID);
          api.fetch(
            null,
            `request-passcode` +
              `?userEmail=${encodeURIComponent(
                dcdStorage.getItem("userEmail")
              )}`
          );
        }}
      >
        Request passcode
      </Button>
    );
  }

  function RequestSessionId(props) {
    return (
      <Button
        style={styles.button}
        mode="contained"
        onPress={async (evt) => {
          const apiRequest =
            `request-session-id` +
            `?userEmail=${encodeURIComponent(
              dcdStorage.getItem("userEmail")
            )}` +
            `&passcode=${encodeURIComponent(dcdStorage.getItem("passcode"))}`;
          setState(waiting);
          api.fetch(navigation, apiRequest).then((resp) => {
            if (resp && resp.SessionID) {
              setState(inputEmail | requestPasscode);
              dcdData.prime();
              navigation.navigate("ViewOptions");
            } else {
              setState(badPasscode);
            }
          });
        }}
      >
        Go
      </Button>
    );
  }

  /* processLoginLink() checks for a query on first load:
    Your passcode is 007228. Please follow this link: <a
    https://app.dcd-gotv.org?userEmail=michael@stanford.cc&passcode=0072228
    http://localhost:19006?userEmail=michael@stanford.cc&passcode=214314
    */
  async function processLoginLink(query) {
    let parts = decodeURIComponent(query).split("&");
    if (parts.length !== 2) return; // badly formed query

    const auth = {};
    parts.forEach((part) => {
      let a = part.split("=");
      if (a.length === 2) auth[a[0]] = a[1];
    });

    if (!(auth.userEmail && auth.passcode)) return; // badly formed query

    setState(waiting);
    return api
      .fetch(
        null,
        `request-session-id` +
          `?userEmail=${encodeURIComponent(auth.userEmail)}` +
          `&passcode=${encodeURIComponent(auth.passcode)}`
      )
      .then((resp) => {
        mylog(`2 request-session-id response is: %o`, resp);

        if (resp && resp.SessionID) {
          dcdData.prime();
          return true; // isValid
        } else {
          return false;
        }
      });
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    // justifyContent: 'top',
  },
  heading: {
    margin: "2rem",
    fontFamily: "sans-serif",
    fontWeight: "bold",
  },
  textInput: {
    margin: "1rem",
    width: "400px",
  },
  button: {
    margin: "2rem",
    width: "400px",
  },
});

/*                                  *  *  *                                   */
