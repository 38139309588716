import { useState, useEffect } from "react";
import { View } from "react-native";
import { ActivityIndicator } from "react-native-paper";

import getAddressData from "./get-address-data";
import EditAddressPopup from "./EditAddressPopup";
import ViewNotesPopup from "./ViewNotesPopup";
import ViewDocumentsPopup from "./ViewDocumentsPopup";
import "./Table.css";
import { expo as settings } from "../app.json";

const logIt = settings.logIt;
let mylog = (...a) => {};
if (logIt)
  mylog = (...a) => {
    console.log("ShowAddressData:", ...a);
  };

export default ShowAddressData;

function ShowAddressData({ navigation, route }) {
  const addressSpec = route.params.addressData;

  mylog("addressSpec: ", addressSpec);

  const [addressesView, setAddressesView] = useState(waitingView());
  const [isEditClickOpen, setIsEditClickOpen] = useState(false);
  const [isViewNotesClickOpen, setIsViewNotesClickOpen] = useState(false);
  const [isViewDocumentsClickOpen, setIsViewDocumentsClickOpen] =
    useState(false);

  function toggleEditClickPopup() {
    setIsEditClickOpen(!isEditClickOpen);
  }
  function toggleViewNotesClickPopup() {
    setIsViewNotesClickOpen(!isViewNotesClickOpen);
  }
  function toggleViewDocumentsClickPopup() {
    setIsViewDocumentsClickOpen(!isViewDocumentsClickOpen);
  }

  let title = "Show Address";
  useEffect(() => {
    let title = addressSpec
      ? `Precinct ${addressSpec.precinctText}`
      : `Missing Address Details`;
    if (addressSpec != null) {
      if (addressSpec.streetText != null) {
        title += ` - ${addressSpec.streetText}`;
      }
      if (addressSpec.propertyTypeText != null) {
        title += ` - ${addressSpec.propertyTypeText}`;
      }
      mylog("Title = ", title);
    }

    navigation.setOptions({
      title: title,
    });

    getAddressData({
      navigation,
      addressSpec,
      setAddressesView,
      toggleEditClickPopup,
      toggleViewNotesClickPopup,
      toggleViewDocumentsClickPopup,
    });
  }, []);

  return (
    <View>
      {isEditClickOpen && (
        <EditAddressPopup toggleEditClickPopup={toggleEditClickPopup} />
      )}
      {isViewNotesClickOpen && (
        <ViewNotesPopup toggleViewNotesClickPopup={toggleViewNotesClickPopup} />
      )}
      {isViewDocumentsClickOpen && (
        <ViewDocumentsPopup
          toggleViewDocumentsClickPopup={toggleViewDocumentsClickPopup}
        />
      )}
      {!isEditClickOpen && addressesView}
    </View>
  );
}

function waitingView() {
  return <ActivityIndicator size="large" style={{ marginTop: "4rem" }} />;
}

/*                                  *  *  *                                   */
