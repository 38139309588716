import { StyleSheet } from "react-native";
import dcdStorage from "./dcdstorage";

import "./map.css";
import api from "./api";
import { expo as settings } from "../app.json";

const logIt = settings.logIt;
let mylog = () => {};
if (logIt)
  mylog = (...a) => {
    console.log("update_address:", ...a);
  };

export default { update_address };

async function update_address(editAddress) {
  let url = "update-address";
  let sep = "?";
  if (editAddress.address_geo_id != null) {
    url +=
      sep + `address_geo_id=${encodeURIComponent(editAddress.address_geo_id)}`;
    sep = "&";
  }
  if (editAddress.property_type != null) {
    url +=
      sep + `property_code=${encodeURIComponent(editAddress.property_type)}`;
  }
  if (editAddress.gated != null) {
    url += sep + `gated=${encodeURIComponent(editAddress.gated)}`;
  }
  if (editAddress.estimated_number_of_units != null) {
    url +=
      sep +
      `estimated_number_of_units=${encodeURIComponent(
        editAddress.estimated_number_of_units
      )}`;
  }
  if (editAddress.subdivision_name != null) {
    url +=
      sep +
      `subdivision_name=${encodeURIComponent(editAddress.subdivision_name)}`;
  }
  if (editAddress.complex_name != null) {
    url += sep + `complex_name=${encodeURIComponent(editAddress.complex_name)}`;
  }
  if (editAddress.contact_name != null) {
    url += sep + `contact_name=${encodeURIComponent(editAddress.contact_name)}`;
  }
  if (editAddress.contact_phone != null) {
    url +=
      sep + `contact_phone=${encodeURIComponent(editAddress.contact_phone)}`;
  }
  if (editAddress.number_of_buildings != null) {
    url +=
      sep +
      `number_of_buildings=${encodeURIComponent(
        editAddress.number_of_buildings
      )}`;
  }

  mylog("url", url);
  let data;
  let response;
  try {
    response = api.fetch(null, url);
  } catch (err) {
    console.error(err);
    return;
  }
  mylog("response: ", response);
  data = await response.then();
  mylog("data: ", data);

  // -----------------------------------------
}
