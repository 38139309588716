import { StyleSheet } from "react-native";
import dcdStorage from "./dcdstorage";

import "./map.css";
import api from "./api";
import ReadOnlyAddressRow from "./ReadOnlyAddressRow";
import { expo as settings } from "../app.json";

const logIt = settings.logIt;
let mylog = () => {};
if (logIt)
  mylog = (...a) => {
    console.log("get-address-data:", ...a);
  };

export default getAddressData;

async function getAddressData({
  navigation,
  addressSpec,
  setAddressesView,
  toggleEditClickPopup,
  toggleViewNotesClickPopup,
  toggleViewDocumentsClickPopup,
}) {
  mylog("addressSpec: ", addressSpec);
  let precinct = addressSpec.precinctText;
  let streetKey = addressSpec.streetKeyText;
  let propertyCode = addressSpec.propertyTypeText;
  let url = "get-addresses";
  let sep = "?";
  if (precinct != null) {
    url += sep + `precinct=${encodeURIComponent(precinct)}`;
    sep = "&";
  }
  if (streetKey != null) {
    url += sep + `streetId=${encodeURIComponent(streetKey)}`;
    sep = "&";
  }
  if (propertyCode != null && propertyCode != "X") {
    propertyCode = propertyCode.charAt(0).toUpperCase();
    url += sep + `propertyCode=${encodeURIComponent(propertyCode)}`;
  }

  mylog("url", url);
  let data;
  let response;
  try {
    response = api.fetch(navigation, url);
  } catch (err) {
    console.error(err);
    return;
  }
  mylog("response: ", response);
  data = await response.then();
  mylog("data: ", data);

  if (!data) return;

  setAddressesView(
    <form>
      <table>
        <thead>
          <tr>
            <th>Address</th>
            <th>Property Type</th>
            <th>Gated</th>
            <th>Estimated Units</th>
            <th>Subdivision Name</th>
            <th>Complex Name</th>
            <th>Contact Name</th>
            <th>Contact Phone</th>
            <th>Number of Buildings</th>
            <th>Number of Registered Voters</th>
            <th>Number of Un-registered Voters</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((address) => (
            <ReadOnlyAddressRow
              address={address}
              handleEditClick={handleEditClick}
              handleViewNotesClick={handleViewNotesClick}
              handleViewDocumentsClick={handleViewDocumentsClick}
            />
          ))}
        </tbody>
      </table>
    </form>
  );

  function handleEditClick(event, address) {
    event.preventDefault();
    mylog("handleEditClick for", address);
    const addressCopy = { ...address };
    dcdStorage.setItem("origAddress", address);
    dcdStorage.setItem("editAddress", addressCopy);
    toggleEditClickPopup();
  }

  function handleViewNotesClick(event, address) {
    //alert('Under Construction: Popup Edit Form')
    event.preventDefault();
    mylog("handleViewNotesClick for", address);
    const addressCopy = { ...address };
    dcdStorage.setItem("origAddress", address);
    dcdStorage.setItem("editAddress", addressCopy);
    toggleViewNotesClickPopup();
  }

  function handleViewDocumentsClick(event, address) {
    //alert('Under Construction: Popup Edit Form')
    event.preventDefault();
    mylog("handleViewDocumentsClick for", address);
    const addressCopy = { ...address };
    dcdStorage.setItem("origAddress", address);
    dcdStorage.setItem("editAddress", addressCopy);
    toggleViewDocumentsClickPopup();
  }

  mylog("END getAddressData *******************************");
}

const styles = StyleSheet.create({
  voter: {
    margin: "8px",
  },
  td: {
    display: "table-cell",
  },
});

/*                                  *  *  *                                   */
