/*
  dcdstorage.js provides global states and 'persistent' storage

  It stores two categories of data
    1. global state data that goes away on reload
    2. persistent data that is preserved for an entire session

*/

import * as SecureStore from "expo-secure-store";
import * as Device from "expo-device";
import { expo as settings } from "../app.json";

const logIt = settings.logIt;
let mylog = (...a) => {};
if (logIt)
  mylog = (...a) => {
    console.log("dcdstorage: ", ...a);
  };

const persistentStorage = {};
const cache = {};

export default {
  initialize,
  setItem,
  getItem,
  removeItem,
  getAllKeys,
  clear,
};

let psType;

async function initialize() {
  mylog("storage.initialize()");
  if (Device.brand) {
    // we are in a phone
    psType = "SecureStore";
    persistentStorage.getAllKeys = ssGetAllKeys;
    persistentStorage.getItem = ssGetItem;
    persistentStorage.removeItem = ssRemoveItem;
    persistentStorage.setItem = ssSetItem;
  } else {
    // we are in a browser
    mylog("localStorage: ", localStorage);
    psType = "localStorage";
    persistentStorage.getAllKeys = lsGetAllKeys;
    persistentStorage.getItem = lsGetItem;
    persistentStorage.removeItem = lsRemoveItem;
    persistentStorage.setItem = lsSetItem;
  }

  // prime the cache from the persistent storage
  const keys = await persistentStorage.getAllKeys();
  mylog(`${psType} contains ${keys.length} keys:`);
  for (let i = 0; i < keys.length; i++) {
    mylog("   Key " + i + " = ", keys[i]);
    const item = await persistentStorage.getItem(keys[i]);
    cache[keys[i]] = item;
  }
  mylog("dcdStorage initialized");
  return cache;
}

// -------------------------------------- works with both

function setItem(key, value, persistent = false) {
  mylog(`setItem persistent=${persistent} cache[${key}] to  `, value);
  cache[key] = value;
  if (persistent) {
    persistentStorage.setItem(key, value);
  }
}

function getItem(key) {
  mylog(`getItem ${key}  `, cache[key]);

  return cache[key] || null;
}

function removeItem(key) {
  delete cache[key];
  return persistentStorage.removeItem(key);
}

function getAllKeys() {
  return Object.keys(cache);
}
function clear(opts = { except: [] }) {
  const keys = getAllKeys();
  for (let i = 0; i < keys.length; i++) {
    if (opts.except.indexOf(keys[i]) === -1) {
      removeItem(keys[i]);
    }
  }
}

// -------------------------------------- localStorage in browser

async function lsGetAllKeys() {
  return new Promise((resolve) => {
    resolve(Object.keys(localStorage));
  });
}
async function lsGetItem(k) {
  return new Promise((resolve) => {
    resolve(JSON.parse(localStorage.getItem(k) || null));
  });
}

async function lsSetItem(k, v) {
  return new Promise((resolve, reject) => {
    resolve(localStorage.setItem(k, JSON.stringify(v)));
  });
}

async function lsRemoveItem(k) {
  return new Promise((resolve, reject) => {
    resolve(localStorage.removeItem(k));
  });
}

// -------------------------------------- Secure Storage on phone

async function ssGetAllKeys() {
  const ssKeys = (await SecureStore.getItemAsync("ssKeys")) || {};
  cache.ssKeys = ssKeys;
  return Object.keys(ssKeys);
}

async function ssGetItem(key) {
  return JSON.parse((await SecureStore.getItemAsync(key)) || null);
}

async function ssSetItem(key, value) {
  const ssKeys = cache.ssKeys || {};
  if (!ssKeys[key]) {
    ssKeys.key = key;
    cache.ssKeys = ssKeys;
    SecureStore.setItemAsync("ssKeys", JSON.stringify(ssKeys));
  }
  SecureStore.setItemAsync(key, JSON.stringify(value));
}

async function ssRemoveItem(key) {
  const ssKeys = cache.ssKeys || {};
  if (!ssKeys[key]) {
    return;
  }
  delete ssKeys[key];
  cache.ssKeys = ssKeys;
  SecureStore.setItemAsync("ssKeys", JSON.stringify(ssKeys));
  SecureStore.deleteItemAsync("key");
}

/*                                  *  *  *                                   */
