import { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { RadioButton, Text } from "react-native-paper";

import dcdStorage from "./dcdstorage";
import SpecifyBlock from "./SpecifyBlock";
import SpecifyPrecinct from "./SpecifyPrecinct";
import api from "./api";
import { expo as settings } from "../app.json";

const logIt = settings.logIt;
let mylog = () => {};
if (logIt) mylog = console.log;

export default ViewOptions;

function ViewOptions({ navigation }) {
  useEffect(async () => {
    navigation.setOptions({
      headerLeft: logout,
      headerRight: admin,
    });
  });

  return (
    <View style={styles.container}>
      <SelectPeople />
      <SelectTurf />
    </View>
  );

  function logout(props) {
    return (
      <Text
        style={{
          marginLeft: "1rem",
          padding: "5px",
          height: "2rem",
          fontSize: "12pt",
          color: "#27b",
        }}
        onPress={() => {
          api.sessionID = null;
          navigation.navigate("Login");
        }}
      >
        Log out
      </Text>
    );
  }

  function admin(props) {
    return (
      <Text
        style={{
          marginLeft: "1rem",
          padding: "5px",
          height: "2rem",
          fontSize: "12pt",
          color: "#27b",
        }}
        onPress={() => {
          navigation.navigate("Admin");
        }}
      >
        Admin
      </Text>
    );
  }

  function SelectPeople(props) {
    const [checked, setChecked] = useState("Registered Voters");

    return (
      <View style={{ marginTop: "2rem" }}>
        <table border="2">
          <thead>
            <tr>
              <td>
                <b>Choose person type for search</b>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <DcdRadioButtonPT label="Registered Voters" checked={true} />
                <DcdRadioButtonPT label="Un-Registered Voters" />
              </td>
            </tr>
          </tbody>
        </table>
      </View>
    );

    function DcdRadioButtonPT(props) {
      return (
        <Text style={styles.checkboxWithLabel}>
          <RadioButton
            value={props.label}
            status={checked === props.label ? "checked" : "unchecked"}
            onPress={() => {
              dcdStorage.setItem("PersonType", props.label[0]);
              setChecked(props.label);
            }}
          />
          <Text style={styles.label}>{props.label}</Text>
        </Text>
      );
    }
  }

  function SelectTurf(props) {
    const [checked, setChecked] = useState("Block");

    return (
      <View>
        <br />
        <Text style={styles.sectionTitle}>Search By</Text>
        <Text style={styles.radioButtonContainer}>
          <DcdRadioButton label="Precinct" />
          <DcdRadioButton label="Block" />
          {/*<DcdRadioButton label='Building' />*/}
        </Text>
        {(checked === "Block" || checked === "Building") && (
          <SpecifyBlock navigation={navigation} type={checked} />
        )}
        {checked === "Precinct" && <SpecifyPrecinct navigation={navigation} />}
      </View>
    );

    function DcdRadioButton(props) {
      return (
        <Text style={styles.checkboxWithLabel}>
          <RadioButton
            value={props.label}
            status={checked === props.label ? "checked" : "unchecked"}
            onPress={() => setChecked(props.label)}
          />
          <Text style={styles.label}>{props.label}</Text>
        </Text>
      );
    }
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    // justifyContent: 'top',
  },
  heading: {
    margin: "2rem",
    fontFamily: "sans-serif",
    fontWeight: "bold",
  },
  textInput: {
    margin: "1rem",
    width: "400px",
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "row",
  },
  radioButtonContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "2rem",
    marginLeft: "2rem",
  },
  checkboxWithLabel: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
  },
  checkbox: {
    alignSelf: "center",
  },
  label: {
    margin: 4,
    marginTop: "10px",
  },
  sectionTitle: {
    textAlign: "center",
    textDecorationLine: "underline",
    fontWeight: "bold",
    fontSize: 20,
  },
});
