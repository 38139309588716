/* SpecifyBlock.jsx - this was the file that used axios
 */

import { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { Button, TextInput } from "react-native-paper";

import dcdStorage from "./dcdstorage";

// import Cities from './Cities'
import Streets from "./Streets";
import { expo as settings } from "../app.json";

export default SpecifyBlock;

const logIt = settings.logIt;
let mylog = () => {};
if (logIt) mylog = console.log;

function SpecifyBlock({ navigation, type }) {
  dcdStorage.setItem("streetKeyText", "");
  dcdStorage.setItem("streetText", "");
  dcdStorage.setItem("blockNumber", "");

  return (
    <View>
      {/*<Cities />*/}
      <Streets />
      <BlockOrBuilding />
      <GoButton />
    </View>
  );

  function BlockOrBuilding(props) {
    const [blockNumber, setBlockNumber] = useState("");
    return (
      <TextInput
        style={styles.textInput}
        label={type + " number"}
        value={blockNumber}
        onChangeText={(blockNumber) => {
          setBlockNumber(blockNumber);
          dcdStorage.setItem("blockNumber", blockNumber);
        }}
        keyboardType="numeric"
      />
    );
  }

  function GoButton(props) {
    return (
      <Button
        style={{ width: "100px", margin: "auto", marginTop: "2rem" }}
        mode="contained"
        onPress={() => {
          const streetText = dcdStorage.getItem("streetText").trim();
          const streetKeyText = dcdStorage.getItem("streetKeyText");
          const blockNumber = dcdStorage.getItem("blockNumber");

          if (!streetText) {
            alert("Needs a street name");
            return;
          }
          if (!blockNumber.trim()) {
            alert("Needs a block number");
            return;
          }
          mylog(
            `Calling ShowVoterData for ${blockNumber} ${streetText} street key ${streetKeyText}`
          );
          navigation.navigate("ShowVoterData", {
            blockData: { blockNumber, streetText, streetKeyText },
          });
        }}
      >
        Go
      </Button>
    );
  }
}

const styles = StyleSheet.create({
  textInput: {
    margin: "1rem",
    width: "400px",
  },
});

/*                                  *  *  *                                   */
