import { StyleSheet } from "react-native";
import { useState } from "react";
import dcdStorage from "./dcdstorage";
import Select from "react-select";
import "./EditAddressPopup.css";
import UpdateDB from "./UpdateDB";
import { expo as settings } from "../app.json";

const logIt = settings.logIt;
let mylog = (...a) => {};
if (logIt)
  mylog = (...a) => {
    console.log("ViewDocumentsPopup:", ...a);
  };

function ViewDocumentsPopup(props) {
  const editAddress = dcdStorage.getItem("editAddress");
  mylog("editAddress: ", editAddress);

  return (
    <div className="popup-box">
      <div className="box">
        <table>
          <tbody>
            <tr>
              <td>Address</td>
              <td>{editAddress.full_address}</td>
            </tr>
            <tr>
              <td>Documents</td>
              <td>TBD</td>
            </tr>
            <tr>
              <td>Action</td>
              <td>
                <button
                  type="button"
                  onClick={(event) => cancelButton(event, editAddress)}
                >
                  Cancel
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );

  function cancelButton(event, editAddress) {
    mylog("cancelButton: event: ", event);
    dcdStorage.removeItem("origAddress");
    dcdStorage.removeItem("editAddress");
    props.toggleViewDocumentsClickPopup();
  }
}

export default ViewDocumentsPopup;

const styles = StyleSheet.create({
  checkboxContainer: {
    display: "flex",
    flexDirection: "row",
  },
  label: {
    margin: 4,
    marginTop: "10px",
  },
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
});
