/*
  This is what the documentation says:
    https://create-react-app.dev/docs/adding-custom-environment-variables/

    There is also a built-in environment variable called NODE_ENV. You can
    read it from process.env.NODE_ENV. When you run npm start, it is always
    equal to 'development', when you run npm test it is always equal
    to 'test', and when you run npm run build to make a production bundle,
    it is always equal to 'production'. You cannot override NODE_ENV
    manually. This prevents developers from accidentally deploying a slow
    development build to production.
*/

import dcdStorage from "./dcdstorage";
import { expo as settings } from "../app.json";

const logIt = settings.logIt;
let mylog = () => {};
if (logIt)
  mylog = (...a) => {
    console.log("api:", ...a);
  };

const env = process.env || { NODE_ENV: "unknown" };

const api = createAPI(); // this is a singleton

export default api;

function createAPI() {
  let sessionID = null;
  const api = Object.create(null, {
    apiURL: { value: getApiURL() },
    sessionID: {
      get: function () {
        if (!sessionID) {
          mylog("getting sessionID from dcdStorage");
          sessionID = dcdStorage.getItem("sessionID");
        }
        return sessionID;
      },
      set: function (val) {
        sessionID = val;
        if (sessionID) {
          mylog("setting sessionID to", val);
          dcdStorage.setItem("sessionID", sessionID, true);
        } else {
          mylog("clearing sessionID");
          dcdStorage.clear({ except: ["userEmail", "loginErrorMessage"] });
        }
        return val;
      },
    },
    fetch: { value: apiFetch },
  });
  return api;
}

/* apiFetch adds a session id header to a regular fetch invocation. It
  also logs the user out if the API call returns a 401 status.
  Example from MDN:
  fetch('https://example.com/profile', {
    method: 'POST', // or 'PUT'
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  */
// let busy = false
async function apiFetch(navigation, apiRequest, init = { headers: {} }) {
  // if ( busy ) return
  // busy = true
  init.headers["x-dcd-session-id"] = this.sessionID;

  mylog(`fetching ${this.apiURL + apiRequest}`);
  mylog(`sessionID is ${this.sessionID}`);
  const response = await fetch(this.apiURL + apiRequest, init);
  mylog("response: ", response);
  // busy = false
  if (response.status === 401) {
    dcdStorage.setItem(
      "loginErrorMessage",
      "Your User Login could not be authenticated.",
      true
    );
    let sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(1000);
    window.location.reload();
    // Need to check for canGoBack because we can have multiple API
    // requests in flight at once, and after the first time through here
    // there will be nothing to pop. This causes an error message in
    // development which is ignored in production.
    mylog("401 error - clearing sessionID, returning null");
    if (navigation && navigation.canGoBack && navigation.canGoBack()) {
      mylog("popping to top");
      navigation.popToTop(); // show the login screen
    }
    this.sessionID = null;

    mylog("apiFetch: return null");
    return null; // tell the caller to abort
  }
  let resp;
  try {
    resp = await response.json();
    if (resp && resp.SessionID) {
      this.sessionID = resp.SessionID;
    }
    mylog(`http response status is ${response.status}`);
  } catch (err) {
    resp = await response.text();
  }
  return resp;
}

// mylog( 'process.env.NODE_ENV: ', nodeEnv )
function getApiURL() {
  let apiURL;
  switch (env.NODE_ENV) {
    case "development":
    case "test":
      // only for testing locally
      // dcdStorage.setItem(
      //   'SessionID', '62ed4175-0bad-4662-9ef8-6ebc733valid', true
      // )

      apiURL = "http://localhost:3000/";
      break;

    case "production":
    default:
      // running in the browser there is no process variable 22 01 18
      // apiURL = 'http://localhost:3000/'
      apiURL = "https://api.dcd-gotv.org/";
      break;
  }
  mylog("apiURL is: ", apiURL);
  return apiURL;
}

/*                                  *  *  *                                   */
