import { StyleSheet } from "react-native";
import { useState } from "react";
import dcdStorage from "./dcdstorage";
import Select from "react-select";
import "./EditAddressPopup.css";
import UpdateDB from "./UpdateDB";
import { DataTable } from "react-native-paper";
import { expo as settings } from "../app.json";

const logIt = settings.logIt;
let mylog = (...a) => {};
if (logIt)
  mylog = (...a) => {
    console.log("ViewNotesPopup:", ...a);
  };

function ViewNotesPopup(props) {
  const editAddress = dcdStorage.getItem("editAddress");
  mylog("editAddress: ", editAddress);

  return (
    <>
      <div className="popup-box">
        <div className="box">
          <DataTable>
            <DataTable.Header>
              <DataTable.Title>Dessert</DataTable.Title>
              <DataTable.Title numeric>Calories</DataTable.Title>
              <DataTable.Title numeric>Fat</DataTable.Title>
            </DataTable.Header>

            <DataTable.Row>
              <DataTable.Cell>Address</DataTable.Cell>
              <DataTable.Cell>{editAddress.full_address}</DataTable.Cell>
            </DataTable.Row>
          </DataTable>
          <table>
            <tbody>
              <tr>
                <td>Address</td>
                <td>{editAddress.full_address}</td>
              </tr>
              <tr>
                <td>Notes</td>
                <td>TBD</td>
              </tr>
              <tr>
                <td>Action</td>
                <td>
                  <button
                    type="button"
                    onClick={(event) => cancelButton(event, editAddress)}
                  >
                    Cancel
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <DataTable>
            <DataTable.Header>
              <DataTable.Title>Name</DataTable.Title>
              <DataTable.Title>Email</DataTable.Title>
              <DataTable.Title numeric>Age</DataTable.Title>
            </DataTable.Header>

            <DataTable.Row>
              <DataTable.Cell>John</DataTable.Cell>
              <DataTable.Cell>john@kindacode.com</DataTable.Cell>
              <DataTable.Cell numeric>33</DataTable.Cell>
            </DataTable.Row>

            <DataTable.Row>
              <DataTable.Cell>Bob</DataTable.Cell>
              <DataTable.Cell>test@test.com</DataTable.Cell>
              <DataTable.Cell numeric>105</DataTable.Cell>
            </DataTable.Row>

            <DataTable.Row>
              <DataTable.Cell>Mei</DataTable.Cell>
              <DataTable.Cell>mei@kindacode.com</DataTable.Cell>
              <DataTable.Cell numeric>23</DataTable.Cell>
            </DataTable.Row>
          </DataTable>
        </div>
      </div>
    </>
  );

  function cancelButton(event, editAddress) {
    mylog("cancelButton: event: ", event);
    dcdStorage.removeItem("origAddress");
    dcdStorage.removeItem("editAddress");
    props.toggleViewNotesClickPopup();
  }
}

export default ViewNotesPopup;

const styles = StyleSheet.create({
  checkboxContainer: {
    display: "flex",
    flexDirection: "row",
  },
  label: {
    margin: 4,
    marginTop: "10px",
  },
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
});
