/*
  Precincts.jsx is an input widget
*/

import dcdStorage from "./dcdstorage";
import { useState } from "react";
import { View, StyleSheet } from "react-native";
import { TextInput } from "react-native-paper";
import "./suggestion.css";
import { expo as settings } from "../app.json";

export default Precincts;

const logIt = settings.logIt;
let mylog = () => {};
if (logIt) mylog = console.log;

function Precincts(props) {
  const [precinctText, setPrecinctText] = useState("");
  //const [precinctText, setPrecinctText] = useState("2048"); // BAF Testing
  const [precinctSuggestions, setPrecinctSuggestions] = useState([]);

  //dcdStorage.setItem("precinctText", "2048"); // BAF Testing
  //dcdStorage.setItem("precinctKeyText", "2048"); // BAF Testing

  return (
    <View>
      <TextInput
        style={styles.textInput}
        label="Precinct"
        value={precinctText}
        onChange={(e) => onPrecinctChangeHandler(e.target.value)}
        keyboardType="numeric"
      />
      {precinctSuggestions &&
        precinctSuggestions.map((precinctSuggestion, i) => (
          <div
            key={i}
            className="suggestion col-md-12 justified-content-md-center"
            onClick={() =>
              onPrecinctSuggestionHandler(
                precinctSuggestion.precinct,
                precinctSuggestion.precinct_key
              )
            }
          >
            {precinctSuggestion.chairname}
          </div>
        ))}
    </View>
  );

  function onPrecinctChangeHandler(text) {
    mylog("IN onPrecinctChangeHandler *******************************");
    setPrecinctText(text);

    dcdStorage.setItem("precinctText", text);
    const precincts = dcdStorage.getItem("precincts");

    let precinctMatches = [];
    mylog("Precinct Text: " + text + " length: " + text.length);

    dcdStorage.setItem("precinctKeyText", "");
    let lengthToCheck = 0; // This could be different for physical device

    if (precincts && precincts.filter && text.length > lengthToCheck) {
      const textI = text.toUpperCase();
      precinctMatches = precincts.filter((precinct) => {
        //mylog("Precinct: " + precinct)
        return precinct.precinct.startsWith(textI);
      });
      mylog("matches are ", precinctMatches);
      setPrecinctSuggestions(precinctMatches);
      setPrecinctText(text);
    } else {
      setPrecinctText(text);
      setPrecinctSuggestions([]);
    }
    mylog("OUT onPrecinctChangeHandler *******************************");
  }

  function onPrecinctSuggestionHandler(precinctText, precinctKey) {
    mylog("IN onPrecinctSuggestionHandler *******************************");
    mylog("Precinct Suggestion text: " + precinctText + " Key: " + precinctKey);
    setPrecinctText(precinctText);

    dcdStorage.setItem("precinctText", precinctText);
    dcdStorage.setItem("precinctKeyText", precinctKey);

    setPrecinctSuggestions([]);
    mylog("OUT onPrecinctSuggestionHandler *******************************");
  }
}

const styles = StyleSheet.create({
  textInput: {
    margin: "1rem",
    width: "400px",
  },
});

/*                                  *  *  *                                   */
