import React from "react";
import { DataTable } from "react-native-paper";

const ReadOnlyAddressRow = ({
  address,
  handleEditClick,
  handleViewNotesClick,
  handleViewDocumentsClick,
}) => {
  return (
    <tr>
      <td>{address.full_address}</td>
      <td>{address.property_type}</td>
      <td>{address.gated}</td>
      <td>{address.estimated_number_of_units}</td>
      <td>{address.subdivision_name}</td>
      <td>{address.complex_name}</td>
      <td>{address.contact_name}</td>
      <td>{address.contact_phone}</td>
      <td>{address.number_of_buildings}</td>
      <td>{address.number_of_registered_voters}</td>
      <td>{address.number_of_unregistered_voters}</td>
      <td>
        <button
          type="button"
          onClick={(event) => handleEditClick(event, address)}
        >
          Edit
        </button>
        <br />
        <button
          type="button"
          onClick={(event) => handleViewNotesClick(event, address)}
        >
          View Notes
        </button>
        <br />
        <button
          type="button"
          onClick={(event) => handleViewDocumentsClick(event, address)}
        >
          View Documents
        </button>
      </td>
    </tr>
  );
};

export default ReadOnlyAddressRow;
