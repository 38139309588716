/*
  precinctdata.js manages a list of all the precincts in the county
*/
import dcdStorage from './dcdstorage'
import api from './api'

export default {
  load,
}
let busy = false
function load () {
  if ( dcdStorage.getItem( 'precincts' ) ) {
    return
  }
  if ( busy ) return
  if ( api.sessionID ) {
    busy = true
    return api.fetch( () => {}, 'get-precincts' ).then( ( data = null ) => {

      dcdStorage.setItem( 'precincts', data, true )
      busy = false
      return data
    } )
  } else {
    return null
  }
}

/*                                  *  *  *                                   */
