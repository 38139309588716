import { useState, useEffect } from "react";
import { View } from "react-native";
import { Tabs, TabScreen } from "react-native-paper-tabs";
import { ActivityIndicator } from "react-native-paper";
import dcdStorage from "./dcdstorage";
import getBlockData from "./get-block-data";
import getPrecinctData from "./get-precinct-data";
import getPersonData from "./get-person-data";
import { expo as settings } from "../app.json";

const logIt = settings.logIt;
let mylog = (...a) => {};
if (logIt)
  mylog = (...a) => {
    console.log("ShowVoterData:", ...a);
  };

export default ShowVoterData;

function ShowVoterData({ navigation, route }) {
  const blockSpec = route.params.blockData;
  const precinctSpec = route.params.precinctData;

  const [voterView, setVoterView] = useState(waitingView());
  const [mapView, setMapView] = useState(waitingView());

  const personType = dcdStorage.getItem("PersonType");

  let title;

  if (personType == "U") {
    if (blockSpec) {
      title = `Unregistered Voters near ${blockSpec.blockNumber} ${blockSpec.streetText}`;
    } else {
      title = `Unregistered Voters in Precinct ${precinctSpec.precinctText}`;
    }
  } else {
    if (blockSpec) {
      title = `Registered Voters on block ${blockSpec.blockNumber} ${blockSpec.streetText}`;
    } else {
      title = `Registered Voters in Precinct ${precinctSpec.precinctText}`;
    }
  }

  useEffect(() => {
    navigation.setOptions({
      title: title,
    });

    getPersonData({
      navigation,
      blockSpec,
      precinctSpec,
      setVoterView,
      setMapView,
    });

    mylog("stop");
  }, []);

  return (
    <View>
      <Tabs
        disableSwipe={true} // (default=false) disable left/right swipe
        defaultIndex={1}
      >
        <TabScreen label="Voters">{voterView}</TabScreen>
        <TabScreen label="Map" icon="compass">
          {mapView}
        </TabScreen>
      </Tabs>
    </View>
  );
}

function waitingView() {
  return <ActivityIndicator size="large" style={{ marginTop: "4rem" }} />;
}
