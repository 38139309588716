import { FlatList, View, StyleSheet } from "react-native";
import { Text } from "react-native-paper";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import dcdStorage from "./dcdstorage";
import L from "leaflet";

import api from "./api";
import "./map.css";
import { expo as settings } from "../app.json";

const logIt = settings.logIt;
let mylog = () => {};
if (logIt)
  mylog = (...a) => {
    console.log("getBlockData:", ...a);
  };

const blueMarker = new L.icon({
  iconUrl: require("./Icons/mm_20_blue.png"),
  popupAnchor: [0, 0],
  iconSize: [20],
});

const redMarker = new L.icon({
  iconUrl: require("./Icons/mm_20_red.png"),
  popupAnchor: [0, 0],
  iconSize: [20],
});

const purpleMarker = new L.icon({
  iconUrl: require("./Icons/mm_20_purple.png"),
  popupAnchor: [0, 0],
  iconSize: [20],
});

const grayMarker = new L.icon({
  iconUrl: require("./Icons/mm_20_gray.png"),
  popupAnchor: [0, 0],
  iconSize: [20],
});

export default getBlockData;

async function getBlockData({
  navigation,
  blockSpec,
  setVoterView,
  setMapView,
}) {
  mylog("Enter");

  mylog(blockSpec);

  const personType = dcdStorage.getItem("PersonType");
  const blockNumber = blockSpec.blockNumber;
  const streetKey = blockSpec.streetKeyText;
  let voters = [];
  let latitudeTotal = 0.0;
  let longitudeTotal = 0.0;
  let cposition = [];
  const objStreet = {};

  let url;

  let zoomlevel;

  if (personType == "U") {
    zoomlevel = 14;
    url =
      `get-persons-by-address` +
      `?voterType=U` +
      `&streetNumber=${encodeURIComponent(blockNumber)}` +
      `&streetId=${encodeURIComponent(streetKey)}`;
  } else {
    zoomlevel = 20;
    url =
      `get-persons-by-address` +
      `?voterType=R` +
      `&streetNumber=${encodeURIComponent(blockNumber)}` +
      `&streetId=${encodeURIComponent(streetKey)}`;
  }

  mylog("URL: ", url);
  let data;

  try {
    data = await api.fetch(navigation, url);
  } catch (err) {
    console.error(err);
    return;
  }
  if (!data) return; // api.fetch failed authentication

  mylog("data: ", data);

  const voterRow = [];
  data.forEach(function (voter) {
    const { latitude, longitude } = voter;
    latitudeTotal += parseFloat(latitude);
    longitudeTotal += parseFloat(longitude);
  });

  data.forEach(function (voter) {
    mylog("voter: ", voter);
    const { address, property_type, People } = voter;
    voterRow.push([" "]);
    voterRow.push([
      "> ",
      address,
      " - ",
      "Property Type: " + property_type,
      "===================",
    ]);
    People.forEach(function (person) {
      const { name, age, partycode, person_id, unit_type, unit } = person;
      let showAge = "";
      if (age > 0) {
        showAge = "Age: " + age;
      }
      let showParty = "";
      if (partycode != " ") {
        showParty = "Party: " + partycode;
      }
      voterRow.push([
        "    * ",
        name,
        showAge,
        showParty,
        person_id,
        unit_type,
        unit,
      ]);
      //voterRow.sort((a, b) => {
      //  return `${a[0]}${a[1]}` < `${b[0]}${b[1]}` ? -1 : 1;
      //});
    });
  });

  voters = data;
  cposition = [latitudeTotal / data.length, longitudeTotal / data.length];

  mylog("latitudeTotal: " + latitudeTotal);
  mylog("longitudeTotal: " + longitudeTotal);
  mylog("response.data.length): " + data.length);
  mylog("cposition: ", cposition);

  if (cposition.length < 2 || Object.is(cposition[0], NaN)) {
    cposition = [32.776665, -96.796989]; //Dallas
  }

  setMapView(
    <MapContainer center={cposition} zoom={zoomlevel} scrollWheelZoom={false}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {voters.map((voter) => (
        <Marker
          key={voter.address_geo_id}
          position={[voter.latitude, voter.longitude]}
          icon={getMarker(voter)}
        >
          <Popup>
            {voter.address}
            <br /> {listPerson(voter.People, 0)}
            <br /> {listPerson(voter.People, 1)}
            <br /> {listPerson(voter.People, 2)}
            <br /> {listPerson(voter.People, 3)}
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );

  setVoterView(
    <FlatList
      data={voterRow}
      renderItem={({ item }) => (
        <Text key={`voter-` + item[4]} style={styles.voter}>
          {item[0]} {item[1]} {item[2]} {item[3]} {item[5]} {item[6]}
        </Text>
      )}
    />
  );
}

function listPerson(people, idx) {
  let person = "";

  if (idx == 0 && people.length > 4) {
    person = `There are ${people.length} people at this address, use the VOTERS tab to see the complete list.`;
  } else if (people.length <= 4 && people.length >= idx + 1) {
    const { name, age, partycode, partycodes } = people[idx];
    let showAge = "";
    if (age > 0) {
      showAge = "Age: " + age;
    }
    let showParty = "";
    if (partycode != " ") {
      showParty = "Party: " + partycode;
    }
    person += `${name} ${showAge} ${showParty}`;
  }

  return person;
}

function listPeople(people) {
  let peopleList = "";

  people.forEach(function (person) {
    mylog("person: ", person);
    const { name, age, partycode, partycodes } = person;
    //peopleList += `<br/> ${name} ${age} ${partycode} ${partycodes}`;
    peopleList += `\n ${name} ${age}`;
  });

  return peopleList;
}

function getMarker(voter) {
  let marker = grayMarker;
  switch (voter.party_lean) {
    case "D":
      marker = blueMarker;
      break;
    case "R":
      marker = redMarker;
      break;
    case "P":
      marker = purpleMarker;
      break;
  }
  return marker;
}

const styles = StyleSheet.create({
  voter: {
    margin: "8px",
  },
});
