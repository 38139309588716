import { FlatList, StyleSheet } from "react-native";
import { Text } from "react-native-paper";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import dcdStorage from "./dcdstorage";
import "./map.css";
import { expo as settings } from "../app.json";

import api from "./api";

const logIt = settings.logIt;
let mylog = () => {};
if (logIt)
  mylog = (...a) => {
    console.log("getPrecinctData:", ...a);
  };

export default getPrecinctData;

async function getPrecinctData({
  navigation,
  precinctSpec,
  setVoterView,
  setMapView,
}) {
  mylog(precinctSpec);

  let uvoters = [];
  let latitudeTotal = 0.0;
  let longitudeTotal = 0.0;
  let cposition = [];
  const personType = dcdStorage.getItem("PersonType");

  mylog("Enter *******************************");

  let url;

  if (personType == "U") {
    url =
      `get-unregistered-voters` +
      `?precinct=${encodeURIComponent(precinctSpec.precinctText)}`;
  } else {
    url =
      `get-registered-voters` +
      `?precinct=${encodeURIComponent(precinctSpec.precinctText)}`;
  }

  let data;

  try {
    data = await api.fetch(navigation, url);
  } catch (err) {
    console.error(err);
    return;
  }
  if (!data) return; // api.fetch failed authentication

  mylog("data: %o", data);

  const voterRow = [];
  data.forEach(function (uvoter) {
    //mylog("uvoter: ", uvoter)
    const { latitude, longitude } = uvoter;
    latitudeTotal += parseFloat(latitude);
    longitudeTotal += parseFloat(longitude);
  });

  data.forEach(function (uvoter) {
    //mylog("uvoter: ", uvoter)
    const {
      first_name,
      last_name,
      street_number,
      street_name,
      street_type,
      person_id,
    } = uvoter;
    voterRow.push([
      first_name,
      last_name,
      street_number,
      street_name,
      street_type,
      person_id,
    ]);
    voterRow.sort((a, b) => {
      return `${a[3]}${a[2]}${a[1]}` < `${b[3]}${b[2]}${b[1]}` ? -1 : 1;
    });
  });

  uvoters = data;
  cposition = [latitudeTotal / data.length, longitudeTotal / data.length];

  mylog("latitudeTotal: " + latitudeTotal);
  mylog("longitudeTotal: " + longitudeTotal);
  mylog("response.data.length): " + data.length);
  mylog("cposition: ", cposition);

  const zoomlevel = 14;

  if (cposition.length < 2 || Object.is(cposition[0], NaN)) {
    cposition = [32.776665, -96.796989]; //Dallas
  }

  setMapView(
    <MapContainer center={cposition} zoom={zoomlevel} scrollWheelZoom={false}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {uvoters.map((uvoter) => (
        <Marker
          key={uvoter.person_id}
          position={[uvoter.latitude, uvoter.longitude]}
        >
          <Popup>
            {uvoter.first_name} {uvoter.last_name}
            <br />
            {uvoter.street_number} {uvoter.street_name} {uvoter.street_type}
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );

  setVoterView(
    <FlatList
      data={voterRow}
      renderItem={({ item }) => (
        <Text key={`voter-` + item[5]} style={styles.voter}>
          {item[0]} {item[1]}, {item[2]} {item[3]} {item[4]}
        </Text>
      )}
    />
  );
  mylog("EXIT *******************************");
}

const styles = StyleSheet.create({
  voter: {
    margin: "8px",
  },
});
