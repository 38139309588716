import { StyleSheet } from "react-native";
import { useState } from "react";
import dcdStorage from "./dcdstorage";
import Select from "react-select";
import "./EditAddressPopup.css";
import UpdateDB from "./UpdateDB";
import { expo as settings } from "../app.json";

const logIt = settings.logIt;
let mylog = (...a) => {};
if (logIt)
  mylog = (...a) => {
    console.log("EditAddressPopup:", ...a);
  };

const propertyTypes = [
  { key: "0", value: "S", label: "Single Family Home" },
  { key: "1", value: "M", label: "Multi-Family Home" },
  { key: "2", value: "A", label: "Apartment Complex" },
  { key: "3", value: "U", label: "Unknown" },
];

const gatedTypes = [
  { key: "0", value: "true", label: "Is Gated" },
  { key: "1", value: "false", label: "Is NOT Gated" },
];

function EditAddressPopup(props) {
  const editAddress = dcdStorage.getItem("editAddress");
  mylog("editAddress: ", editAddress);
  const _estimated_number_of_units = useFormInput(
    editAddress.estimated_number_of_units
  );
  const _subdivision_name = useFormInput(editAddress.subdivision_name);
  const _complex_name = useFormInput(editAddress.complex_name);
  const _contact_name = useFormInput(editAddress.contact_name);
  const _contact_phone = useFormInput(editAddress.contact_phone);
  const _number_of_buildings = useFormInput(editAddress.number_of_buildings);

  let currentProperyType = null;
  switch (editAddress.property_type) {
    case "S":
      currentProperyType = propertyTypes[0];
      break;
    case "M":
      currentProperyType = propertyTypes[1];
      break;
    case "A":
      currentProperyType = propertyTypes[2];
      break;
    case "U":
      currentProperyType = propertyTypes[3];
      break;
  }

  let currentGatedType = null;
  switch (editAddress.gated) {
    case "true":
      currentGatedType = gatedTypes[0];
      break;
    case "false":
      currentGatedType = gatedTypes[1];
      break;
  }

  return (
    <div className="popup-box">
      <div className="box">
        <table>
          <tbody>
            <tr>
              <td>Precinct</td>
              <td>{editAddress.precinct_name}</td>
            </tr>
            <tr>
              <td>Address</td>
              <td>{editAddress.full_address}</td>
            </tr>
            <tr>
              <td>Property Type</td>
              <td>
                <Select
                  value={propertyTypes.value}
                  options={propertyTypes}
                  defaultValue={currentProperyType}
                  onChange={handlePropertyTypeChange}
                />
              </td>
            </tr>
            <tr>
              <td>Gated</td>
              <td>
                <Select
                  value={gatedTypes.value}
                  options={gatedTypes}
                  defaultValue={currentGatedType}
                  onChange={handleGatedTypeChange}
                />
              </td>
            </tr>
            <tr>
              <td>Estimated Units</td>
              <td>
                <input
                  name="estimated_number_of_units"
                  {..._estimated_number_of_units}
                />
              </td>
            </tr>
            <tr>
              <td>Subdivision Name</td>
              <td>
                <input name="subdivision_name" {..._subdivision_name} />
              </td>
            </tr>
            <tr>
              <td>Complex Name</td>
              <td>
                <input name="complex_name" {..._complex_name} />
              </td>
            </tr>
            <tr>
              <td>Contact Name</td>
              <td>
                <input name="contact_name" {..._contact_name} />
              </td>
            </tr>
            <tr>
              <td>Contact Phone</td>
              <td>
                <input name="contact_phone" {..._contact_phone} />
              </td>
            </tr>
            <tr>
              <td>Number of Buildings</td>
              <td>
                <input name="number_of_buildings" {..._number_of_buildings} />
              </td>
            </tr>
            <tr>
              <td>Action</td>
              <td>
                <button
                  type="button"
                  onClick={(event) => saveButton(event, editAddress)}
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={(event) => cancelButton(event, editAddress)}
                >
                  Cancel
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );

  function useFormInput(initialValue) {
    const [value, setValue] = useState(initialValue);

    function handleChange(event) {
      mylog("useFormInput.handelChange: handle Change for event ", event);

      const fieldName = event.target.getAttribute("name");
      const fieldValue = event.target.value;
      mylog(
        `useFormInput.handelChange: Field ${fieldName} changed to ${fieldValue}`
      );

      editAddress[fieldName] = fieldValue;
      mylog(`useFormInput.handelChange: Update address :`, editAddress);

      setValue(event.target.value);
    }

    return {
      value,
      onChange: handleChange,
    };
  }

  function cancelButton(event, editAddress) {
    mylog("cancelButton: event: ", event);
    dcdStorage.removeItem("origAddress");
    dcdStorage.removeItem("editAddress");
    props.toggleEditClickPopup();
  }

  function saveButton(event, editAddress) {
    mylog("saveButton: event: ", event);
    const origAddress = dcdStorage.getItem("origAddress");
    mylog(
      "saveButton: editAddress: ",
      editAddress,
      "origAddress before: ",
      origAddress
    );
    mylog("saveButton: origAddress after: ", origAddress);

    UpdateDB.update_address(editAddress);

    Object.assign(origAddress, editAddress);
    dcdStorage.removeItem("origAddress");
    dcdStorage.removeItem("editAddress");
    props.toggleEditClickPopup();
  }

  function handlePropertyTypeChange(value) {
    mylog("handlePropertyTypeChange: Property Typpe changed to : ", value);

    editAddress.property_type = value.value;
    mylog(`handlePropertyTypeChange: Update address :`, editAddress);
  }

  function handleGatedTypeChange(value) {
    mylog("handleGatedTypeChange: Gated Typpe changed to", value);

    editAddress.gated = value.value;
    mylog(`handleGatedTypeChange: Update address :`, editAddress);
  }

  function handleEditChange(event) {
    event.preventDefault();
    mylog("handleEditChange: value : ", event);

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    mylog(`handleEditChange: Field ${fieldName} changed to ${fieldValue}`);

    editAddress[fieldName] = fieldValue;
    mylog(`handleEditChange: Update address :`, editAddress);
  }
}

export default EditAddressPopup;

const styles = StyleSheet.create({
  checkboxContainer: {
    display: "flex",
    flexDirection: "row",
  },
  label: {
    margin: 4,
    marginTop: "10px",
  },
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
});
