import { useState } from "react";
import { View, StyleSheet } from "react-native";
import { Button, RadioButton, Text } from "react-native-paper";

import dcdStorage from "./dcdstorage";
import Precincts from "./Precincts";
import Streets from "./Streets";
import { expo as settings } from "../app.json";

export default SpecifyAddress;

const logIt = settings.logIt;
let mylog = (...a) => {};
if (logIt)
  mylog = (...a) => {
    console.log("SpecifyAddress:", ...a);
  };

function SpecifyAddress({ navigation }) {
  dcdStorage.setItem("precinctText", "");

  return (
    <View>
      <Precincts />
      <Streets page="SpecifyAddress" />
      <PropertyType />
      <br />
      <GoButton />
    </View>
  );

  function PropertyType(props) {
    const [checked, setChecked] = useState("All Property Types");
    //const [checked, setChecked] = useState("Apartment Complex"); //BAF Testing

    return (
      <View>
        <Text style={styles.radioButtonContainer}>
          <DcdRadioButton label="All Property Types" name="X" />
          <DcdRadioButton label="Single Family Home" name="S" />
          <DcdRadioButton label="Multi-Family Home" name="M" />
          <DcdRadioButton label="Apartment Complex" name="A" />
          <DcdRadioButton label="Unknown Property Type" name="U" />
        </Text>
      </View>
    );

    function DcdRadioButton(props) {
      return (
        <Text style={styles.checkboxWithLabel}>
          <RadioButton
            value={props.label}
            name={props.name}
            status={checked === props.label ? "checked" : "unchecked"}
            onPress={() => {
              setChecked(props.label);
              dcdStorage.setItem("addressPropertyType", props.name);
            }}
          />
          <Text style={styles.label}>{props.label}</Text>
        </Text>
      );
    }
    ß;
  }

  function GoButton(props) {
    return (
      <Button
        style={{ width: "100px", margin: "auto", marginTop: "2rem" }}
        mode="contained"
        onPress={(e) => {
          const precinctText = dcdStorage.getItem("precinctText");
          const streetText = dcdStorage.getItem("streetText");
          const streetKeyText = dcdStorage.getItem("streetKeyText");
          const propertyTypeText = dcdStorage.getItem("addressPropertyType");

          if (!precinctText) {
            alert("Please enter a precinct");
            return;
          }
          mylog(
            "Calling ShowAddressData for precinct " +
              precinctText +
              " street " +
              streetKeyText +
              ":" +
              streetText +
              " propertyTypeText " +
              propertyTypeText
          );
          navigation.navigate("ShowAddressData", {
            addressData: {
              precinctText,
              streetText,
              streetKeyText,
              propertyTypeText,
            },
          });
        }}
      >
        Go
      </Button>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    // justifyContent: 'top',
  },
  heading: {
    margin: "2rem",
    fontFamily: "sans-serif",
    fontWeight: "bold",
  },
  textInput: {
    margin: "1rem",
    width: "400px",
  },
  radioButtonContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "2rem",
    marginLeft: "2rem",
  },
  label: {
    margin: 4,
    marginTop: "10px",
  },
});

/*                                  *  *  *                                   */
